<template>
  <div>
    <div class="d-flex">
      <nav id="breadcrumbs" class="mb-3">
        <ul>
          <li>
            <router-link :to="{ name: 'company.dashboard' }">
              <i class="uil-home-alt"></i> <span> Raporlar</span>
            </router-link>
          </li>
          <li>Atama Raporları</li>
        </ul>
      </nav>
    </div>

    <div class="section-header mb-lg-2 border-0 uk-flex-middle">
      <div class="section-header-left">
        <h2 class="uk-heading-line text-left"><span> Atama Raporları </span></h2>
      </div>
    </div>
    <!--Modal Profie-->

    <div v-if="error" class="uk-alert-danger mb-3" uk-alert>
      <a class="uk-alert-close" uk-close></a>
      <p style="color: red"><i class="uil-exclamation-triangle"></i> {{ error }}</p>
    </div>

    <!--End Modal Profile -->
    <div class="card">
      <!-- Card header -->
      <div class="card-header actions-toolbar border-0">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <a aria-expanded="false" class="btn btn-icon btn-hover btn-circle" href="javascript:void(0)">
              <i class="uil-search"></i>
            </a>
            <div class="uk-drop" style="left: 91px; top: 159.297px;"
              uk-drop="mode: hover; pos: right-center; offset: 0">
              <span class="uk-search uk-search-navbar uk-width-1-1">
                <input v-model="filter.search" :placeholder="$t('general.search')" autofocus=""
                  class="uk-search-input shadow-0 uk-form-small" type="search">
              </span>
            </div>
          </div>
          <!-- <h4 class="d-inline-block mb-0"></h4> -->
          <div class="d-flex">
            <a :uk-tooltip="$t('filters.order.sort')" aria-expanded="false" class="btn btn-icon btn-hover  btn-circle"
              href="javascript:void(0)" title="">
              <i class="uil-list-ui-alt"></i>
            </a>
            <div class="uk-dropdown" uk-dropdown="pos: bottom-right ; mode: click ;animation: uk-animation-scale-up">
              <ul class="uk-nav uk-dropdown-nav">
                <li class="uk-active"><a href="javascript:void(0)" @click="sortOrder('asc', 'name')">{{
              $t('sorting.a_z')
            }}</a></li>
                <li><a href="javascript:void(0)" @click="sortOrder('desc', 'name')">{{ $t('sorting.z_a') }}</a></li>
                <li><a href="javascript:void(0)" @click="sortOrder('asc', 'id')"> {{ $t('sorting.created_at_old') }}</a>
                </li>
                <li><a href="javascript:void(0)" @click="sortOrder('desc', 'id')"> {{ $t('sorting.created_at_new')
                    }}</a>
                </li>
              </ul>
            </div>


            <!-- Filter -->
            <a class="btn btn-icon btn-hover  btn-circle" href="javascript:void(0)"
              :uk-tooltip="$t('general.filter_list')">
              <i class="uil-filter"></i>
            </a>
            <div class="large" id="filterDropdown"
              uk-dropdown="pos: bottom-right ; mode: click ;animation: uk-animation-scale-up">
              <div class="filter-container">
                <div class="uk-child-width-1-2@s uk-grid-small" uk-grid>

                  <div>
                    <h5 class="uk-text-bold mb-2">Atama</h5>
                    <multiselect v-model="assignment_ids" tag-placeholder="Atama Seçiniz" placeholder="Atama Seçiniz"
                      label="name" track-by="id" :options="assignmentList" :allow-empty="true" :searchable="true"
                      deselectLabel="" selectLabel="Seçiniz" :multiple="true" />
                  </div>
                  <div class="uk-flex uk-flex-right p-2">
                    <button class="btn btn-default grey" @click="filterResult"><i class="uil-filter"></i>
                      {{ $t('general.filter') }}</button>
                  </div>
                </div>
              </div>
            </div>
            <a class="btn btn-icon btn-hover  btn-circle" href="javascript:;" :uk-tooltip="$t('filters.export_excell')"
              @click="exportAssignments">
              <i class="fa-file-excel fa"></i>
            </a>
          </div>
        </div>
      </div>
      <!-- Table -->
      <default-loading v-if="user_loading"></default-loading>
      <template v-else>
        <div class="table-responsive">
          <table class="table align-items-center">
            <thead>
              <tr>
                <th scope="col">{{ $t('users.photo') }}</th>
                <th scope="col">ID</th>
                <th scope="col">{{ this.$t('general.assignment_name') }}</th>
                <th scope="col">{{ $t('general.start_date') }}</th>
                <th scope="col">{{ $t('general.end_date') }}</th>
                <th scope="col">{{ this.$t('general.number_of_assigned_users') }}</th>
                <th scope="col">Başlamayan Kullanıcı Sayısı</th>
                <th scope="col">Devam Eden Kullanıcı Sayısı</th>
                <th scope="col">Tamamlayan Kullanıcı Sayısı</th>
                <th scope="col">İçerik Sayısı</th>
                <th scope="col">{{ $t('general.created_at') }}</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody class="list">
              <template v-for="item in formattedItems">
                <tr>
                  <th scope="row">
                    <div class="media align-items-center">
                      <div>
                        <div class="avatar-parent-child" style="width: max-content">
                          <img :src="item.photo_link" :alt="item.name" class="avatar  rounded-circle">
                        </div>
                      </div>
                    </div>
                  </th>
                  <td>{{ item.id }}</td>
                  <td>{{ item.name }}</td>
                  <td> {{ item.start_date }}</td>
                  <td> {{ item.end_date }}</td>
                  <td> {{ item.users_count }}</td>
                  <td> {{ item.users_count_of_not_starting }}</td>
                  <td> {{ item.started_by_users_count }}</td>
                  <td> {{ item.completed_by_users_count }}</td>
                  <td> {{ item.contents_count }}</td>
                  <td> {{ item.created_at }}</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <pagination :pagination="pagination" class="mt-2 mb-3" @changePage="setPage($event)"></pagination>
      </template>
    </div>
  </div>
</template>

<script>
import reports, {
  ERROR,
  GET_ITEMS,
  ITEMS,
  LOADING,
  MODULE_NAME,
  SUCCESS
} from "@/core/services/store/reports.module";

import store from '@/core/services'
import moment from "moment";
import axios from "axios";
import Pagination from "@/assets/components/Pagination";
import UserProfile from "@/view/company/User/UserProfile";
import DefaultLoading from "@/view/components/loading/DefaultLoading";
import Multiselect from "vue-multiselect";

const _MODULE_NAME = MODULE_NAME;

export default {
  name: "ReportUsers",
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule)
      }
    }

    registerStoreModule(_MODULE_NAME, reports)
  },
  components: { Pagination, UserProfile, DefaultLoading, Multiselect },
  data() {
    return {
      pagination: null,
      activeItem: null,
      page: 1,
      per_page: 10,
      sort_by: "id",
      sort_desc: "desc",
      formattedItems: [],
      assignment_ids: [],
      assignmentList: [],
      error: null,
      filter: {
        search: null,
        order: "desc",
        sort: "id",
      },
      successMessage: "",
    }
  },
  computed: {
    items: {
      get() {
        return store.getters[_MODULE_NAME + '/' + ITEMS]
      },
      set(value) {
      }
    },
    user_loading: {
      get() {
        return store.getters[_MODULE_NAME + '/' + LOADING]
      },
      set(value) {
      }
    },
    errors: {
      get() {
        return store.getters[_MODULE_NAME + '/' + ERROR]
      },
      set(value) {
      }
    },
    success() {
      return store.getters[_MODULE_NAME + '/' + SUCCESS]
    }
  },
  methods: {
    exportAssignments() {
      let assignment_ids = [];
      let self = this;

      if (+self.assignment_ids.length === 0) {
        this.error = this.$t('validation.please_select_assignment');
        return false;
      }
      let params = {
        sort: this.filter.sort,
        order: this.filter.order,
        assignment_ids: assignment_ids,
        like: this.filter.search,
        report: Math.random() * 9999999
      }

      if (self.assignment_ids.length) {
        assignment_ids = self.assignment_ids.filter(item => {
          return item.id
        });

        assignment_ids = assignment_ids.map(item => {
          return item.id
        });

        if (assignment_ids.length) {
          params.assignment_ids = JSON.stringify(assignment_ids);
        }
      }

      axios.get(process.env.VUE_APP_BACKEND_APP_URL + '/api/report/export/assignments', {
        responseType: 'blob',
        params: params,
        headers: this.apiHeaders,
      }).then((response) => {
        let fileURL = window.URL.createObjectURL(new Blob([response.data]));
        let fileLink = document.createElement('a');
        fileLink.href = fileURL;
        let day = moment().format('YYYYMMDDHHMMSS');
        let fileName = 'assignment_report_list_' + day + '.xlsx';
        fileLink.setAttribute('download', fileName);
        document.body.appendChild(fileLink);
        fileLink.click();
      })
    },
    filterResult(filter = null) {
      let self = this;
      let assignment_ids = [];

      if (+self.assignment_ids.length === 0) {
        this.error = this.$t('validation.please_select_assignment');
        return false;
      }

      let filters = {
        like: self.filter.search,
        page: self.page,
        per_page: self.per_page,
        sort: self.filter.sort,
        order: self.filter.order,
      };
      if (self.assignment_ids.length) {
        assignment_ids = self.assignment_ids.filter(item => {
          return item.id
        });

        assignment_ids = assignment_ids.map(item => {
          return item.id
        });

        if (assignment_ids.length) {
          assignment_ids = JSON.stringify(assignment_ids);

          filters['assignment_ids'] = assignment_ids;
        }
      }

      store.dispatch(_MODULE_NAME + '/' + GET_ITEMS, {
        url: 'api/report/assignments',
        filters: filters
      }).then(response => {
        if (response.status) {
          let results = [];
          let items = response.data;
          this.pagination = items;
          items.data.forEach(item => {
            let assign_user = item.users_count
            let completed_user = item.completed_by_users_count
            let started_user = item.started_users_count
            let programCount = 0;
            item.contents_programs.forEach(subItem => {
              programCount += subItem.contents.length;
            })
            results.push({
              id: item.id,
              name: item.name,
              photo_link: item.cover_image_link,
              start_date: item.start_date ? moment(item.start_date).format('DD/MM/YYYY') : '-',
              end_date: item.end_date ? moment(item.end_date).format('DD/MM/YYYY') : '-',
              contents_count: item.contents_count + programCount,
              users_count: assign_user,
              completed_by_users_count: completed_user,
              started_by_users_count: started_user,
              users_count_of_not_starting: assign_user - (started_user + completed_user),
              completed_contents_count: item.completed_contents_count,
              created_at: item.created_at ? moment(item.created_at).format('DD/MM/YYYY') : '-',
            });
          })
          this.formattedItems = results;
        }
      });
    },

    getAssignments() {
      let self = this;
      let filters = {
        page: 0,
        sort: 'id',
        order: 'desc',
      };
      store.dispatch(_MODULE_NAME + '/' + GET_ITEMS, {
        url: 'api/report/get-assignments',
        filters: filters
      }).then(response => {
        self.assignmentList = [
          {
            id: null,
            name: this.$t('general.all'),
          }
        ];
        if (response.status) {
          let results = [];
          let items = response.data;
          items.forEach(item => {
            self.assignmentList.push({
              id: item.id,
              name: item.name,
            });
          })
        }
      });
    },
    sortOrder(order, sort) {
      this.page = 1;
      this.filter.order = order;
      this.filter.sort = sort;

      this.filterResult();
    },
    setPage(page) {
      this.page = page;
      this.filterResult()
    },
    scrollToTop() {
      window.scroll({
        top: 0,
        behavior: 'smooth'
      })
    },
    getFilter(value) {
      this.page = 1;
      this.filterResult(value);
    },
  },
  mounted() {
    this.debouncedFilterSearch = _.debounce(this.filterResult, 500)
    this.getAssignments();

    if (this.$route.params.succesMessage != null) {
      this.successMessage = this.$route.params.succesMessage;
      this.scrollToTop();
    }
  },
  watch: {
    success: function (val) {
      if (val) {
        this.filterResult()
      }
    },
    'filter.search': function (val) {
      this.debouncedFilterSearch()
    },
    formattedInstitution(value) {
      if (value) {
        setTimeout(() => {
          $('.selectpicker1').selectpicker();
        }, 200)
      }
    },
    formattedUserGroup(value) {
      if (value) {
        setTimeout(() => {
          $('.selectpicker2').selectpicker();
        }, 200)
      }
    },
    formattedPosition(value) {
      if (value) {
        setTimeout(() => {
          $('.selectpicker3').selectpicker("refresh").trigger('change');
        }, 200)
      }
    },
    formattedTitle(value) {
      if (value) {
        setTimeout(() => {
          $('.selectpicker4').selectpicker("refresh").trigger('change');
        }, 200)
      }
    },
    formattedRegion(value) {
      if (value) {
        setTimeout(() => {
          $('.selectpicker5').selectpicker("refresh").trigger('change');
        }, 200)
      }
    },
    formattedDepartment(value) {
      if (value) {
        setTimeout(() => {
          $('.selectpicker6').selectpicker("refresh").trigger('change');
        }, 200)
      }
    },
    formattedBranch(value) {
      if (value) {
        setTimeout(() => {
          $('.selectpicker7').selectpicker("refresh").trigger('change');
        }, 200)
      }
    },
    activeItem(value) {
      if (value) {
        this.getUserDetail(value.id);
      }
    }
  },
  beforeDestroy() {
    const modal = UIkit.modal("#modal-profile");
    if (modal) modal.$destroy(true);
  }

};
</script>

<style scoped>
input[type="button"],
input[type="submit"],
span.btn,
.btn {
  display: inline-block;
}

.uk-alert {
  margin: 0;
}
</style>
<style scoped>
@import "~vue-multiselect/dist/vue-multiselect.min.css";

input[type="button"],
input[type="submit"],
span.btn,
.btn {
  display: inline-block;
}

.uk-alert {
  margin: 0;
}
</style>
<style>
.multiselect__tag {
  color: white;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #3e416d;
}

.multiselect__tag-icon {
  background-color: transparent;
  line-height: 2.2;
}

.multiselect__tag-icon:after {
  color: #fff;
}

.multiselect__tag-icon:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.multiselect__input {
  border: unset !important;
}

.multiselect__tags .multiselect__input {
  border: unset !important;
}
</style>
